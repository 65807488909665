import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Link from 'gatsby-link';

const Footer = () => (
    <footer className="section">
    <div className="container">
      <hr/>
      <div className="has-text-centered">
          <p>&copy; 2021 Westlake MUD 1 <span style={{margin: '0 1rem'}}>|</span> Website provided by <OutboundLink href="//touchstonedistrictservices.com/">Touchstone</OutboundLink>
          </p>
          <p><Link to="/legal-notices-and-disclaimers" className="button is-default" style={{textTransform: 'uppercase'}}>Legal Notices and Disclaimers</Link></p>
      </div>
    </div>
  </footer>
)

export default Footer;