import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import logo from "../img/logo.png"

class NavbarMenu extends React.Component {
  render() {
    function toggleMoreInfo() {
      document.querySelector('#more_info').classList.toggle('is-active');
    }
    function toggleSecurityInfo() {
      document.querySelector('#security_info').classList.toggle('is-active');
    }
    function toggleElection() {
      document.querySelector('#election').classList.toggle('is-active');
    }
    return (
      < >
        <Link className="navbar-item" to="/">Home</Link>
        <Link className="navbar-item" to="/bill-payment">Billing</Link>
        <Link className="navbar-item" to="/tax-information">Tax Info</Link>
        <Link className="navbar-item" to="/alerts">Alerts</Link>
        <div id="security_info" className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link" onClick={toggleSecurityInfo}>Security</div>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to="/first-responders">First Responders</Link>
            <Link className="navbar-item" to="/security-coordinator">Security Coordinator</Link>
          </div>
        </div>
        <div id="more_info" className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link" onClick={toggleMoreInfo}>More Info</div>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to="/waste-collection">Waste Collection</Link>
            <Link className="navbar-item" to="/documents">Documents</Link>
            <Link className="navbar-item" to="/board-of-directors">Board of Directors</Link>
            <Link className="navbar-item" to="/district-consultants"> District Consultants </Link>
            <Link className="navbar-item" to="/board-of-directors-elections">Election Information</Link>
            <Link className="navbar-item" to="/useful-links">Useful Links</Link>
            <Link className="navbar-item" to="/calendar">Calendar</Link>
            <Link className="navbar-item" to="/map">Map</Link>
          </div>
        </div>
        <Link className="navbar-item" to="/contact">Contact Us</Link>
      </ >
    )
  }
}

export default NavbarMenu
